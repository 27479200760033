* {
    font-family: Tahoma,Verdana,Segoe,sans-serif;
}

body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    width: 100%;
    font-size: 100%;
    background-color: #fff;
    margin: 0 auto;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

html {
    height: -webkit-fill-available;
}
@-ms-viewport {
    width: device-width;
}
@media (hover:none) {
    /* start mobile styles */
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 16px;
    }
    /* end mobile styles */
}

button {
    background-color: transparent;
}

h1 {
    font-size: 2.2em;
}

h2 {
    font-size: 2.0em;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.6em;
}

h5 {
    font-size: 1.4em;
}

p {
    font-size: 1.2em;
}

a {
    word-wrap: break-word;
}

.bold {
    font-weight: bold;
}

.container {
    padding-top: 70px;
    padding-bottom: 24px;
}

.non-draggable-image {
    -webkit-user-drag: none;
}

.centered {
    text-align: center;
}

.heading {
    font-size: 1.75em;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
}

.site-title {
    font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif!important;
    -webkit-text-decoration-line: overline underline;
    text-decoration-line: overline underline;
}
input:focus,
select:focus,
textarea:focus {
    outline: none;
}


/* Nav: */
.header {
    background-color: #fff;
    height: 42px;
    width: 100%;
    color: black;
    position: fixed;
    z-index: 3;
    border-bottom: gray 1px solid;
}

.logo {
    font-size: 1.5rem;
    color: black;
    border: none;
    outline: none;
    letter-spacing: 1.2px;
    padding: 0 20px 0 10px;
}
.logo:before {
    content: 'CRAFT OTTAWA';
}
.logo:hover:before {
    content: 'Find Your Local';
    letter-spacing: 0.9px;
}

.logo-image {
    height: 38px;
}

.social-link-image {
    width: 20px;
    height: 20px;
}

.menu {
    height: 42px;
    white-space: nowrap;
    text-align: center;
    padding-inline-start: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu Link,
.menu a,
.menu button {
    color: black;
    letter-spacing: 1.2px;
    padding: 0 20px;
    border: none;
    cursor: pointer;
    outline: none;
}
.menu Link:hover,
.menu a:hover,
.menu button:hover {
    color: #ff508e;
    text-decoration: none;
}
@media screen and (max-width: 610px) {
    .logo {
        display: none;
    }
}
@media screen and (max-width: 412px) {
    .logo,
    .menu Link,
    .menu a,
    .menu button {
        padding: 0 10px;
    }
}
@media screen and (max-width: 332px) {
    .logo-image {
        display: none;
    }
}

/* Footer */
#footer {
    border-top: darkgray 1px solid;
    background-color: ghostwhite!important;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: 0.8rem;
    padding: 2px 0;
    z-index: 3;
}

#copyright {
    float: left;
}

#hashtag {
    float: right;
    font-weight: bold;
    padding-right: 10px;
}

#footer-icons {
    float: right;
}

#footer .social-link {
    outline: none;
    padding: 0 14px;
    border: none;
    cursor: pointer;
}
@media screen and (max-width: 505px) {
    #footer .social-link {
        padding: 0 15px;
    }
}
@media screen and (max-width: 466px) {
    #footer .social-link {
        padding: 0 10px;
    }
}
@media screen and (max-width: 425px) {
    #footer .social-link {
        display: none;
    }
}
@media screen and (max-width: 264px) {
    #hashtag {
        display: none;
    }
}

/* Map */
#map-container {
    position: fixed;
    top: 42px!important;
    height: 100%;
    width: 100%;
}

#map {
    position: absolute!important;
    width: 100%;
    height: calc(100% - 67px)!important;
    height: -moz-calc(100% - 67px)!important;
    height: -webkit-calc(100% - 67px)!important;
}

.leaflet-bar a {
    height: 30px!important;
    width: 30px!important;
}

.leaflet-control-search {
    border: 2px solid rgba(0,0,0,0.2)!important;
    background-clip: padding-box!important;
    box-shadow: none!important;
}

.leaflet-control-layers {
    background-clip: none!important;
    border: none!important;
}

a.leaflet-control-layers-toggle {
    width: 60px!important;
    height: 36px!important;
    border: 1.5px solid rgba(0, 145, 0, 0.836);
    border-radius: 0.4em!important;
    text-align: center;
    vertical-align: middle;
    padding: 0;
}
.leaflet-control-layers-toggle:after {
    content: "Maps Markers";
    color: black;
    position: relative;
    font-weight: bold;
}

.leaflet-control-attribution {
    background: rgba(255, 255, 255, 0.7)!important;
}

#location-sharing-link {
    margin-left: 4px;
    margin-right: 4px;
}

#more-dropdown {
    position: absolute;
    z-index: 3;
}

#clear-all-markers {
    background: #FFFFFF;
    width: 60px;
    height: 36px;
    border: 1.5px solid rgba(255, 0, 0, 0.8);
    border-radius: 0.4em!important;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 12px;
    padding: 0;
}

#event-notice {
    position: absolute;
    background: rgba(255, 255, 255, 1);
    z-index: 1;
    top: 53px;
    left: 46px;
    text-align: center;
    font-size: 0.7rem;
    border-radius: 3px;
    padding: 0 5px;
    border: 2px solid rgba(0,0,0,0.2);
}

#upcoming-event {
    color: black;
    text-decoration: none;
    display: inline-block;
}

#upcoming-event:hover {
    text-decoration: none!important;
}

#cycling-key-link {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    bottom: 28px;
    right: 5px;
    text-align: center;
    font-size: 0.7rem;
    border-radius: 3px;
}

#cycling-key-link span {
    font-size: 1rem;
    background: transparent;
}

#cycling-key-link button {
    color: #0078A8;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;
    line-height: 0;
    padding: 0 5px;
    height: 20px;
}

.cycling-key {
    background: rgb(252, 252, 252);
    max-width: 400px!important;
    margin: 0 auto;
    border: 1px solid dimgray;
    color: #08233B;
}

#cycling-key-image {
    max-width: 100%;
}

/* Current Location https://cdn.jsdelivr.net/npm/leaflet.locatecontrol/dist/L.Control.Locate.min.css */
/*# sourceMappingURL=L.Control.Locate.min.css.map */
.leaflet-control-locate a {
    font-size: 1.4em;
    color: #444;
    cursor: pointer;
}
.leaflet-control-locate.active a {
    color: #2074b6;
}
.leaflet-control-locate.active.following a {
    color: #fc8428;
}
.leaflet-control-locate-location circle {
    animation: leaflet-control-locate-throb 4s ease infinite;
}
@keyframes leaflet-control-locate-throb {
    0% {
        stroke-width: 1;
    }
    50% {
        stroke-width: 3;
        transform: scale(0.8, 0.8);
    }
    100% {
        stroke-width: 1;
    }
}

/* Map Popup */
.leaflet-popup-content {
    padding: 1px;
    margin-top: 3px !important;
    margin-bottom: 2px !important;
}

#error-contact {
    margin-left: 2px;
}

#maplink {
    margin-right: 50px;
}

#twitterlink {
    margin-right: 50px;
}

#calendar-icon {
    margin-right: 10px;
}

.leaflet-popup-content-wrapper {
    background-color: #ffffff;
    border: 1px solid grey;
    border-radius: 6px;
    text-align: center !important;
}

#location-title {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0.75rem 0 0;
    padding-bottom: 0.25rem !important;
    word-wrap: break-word;
}

#events-name {
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 10px;
    text-decoration-line: underline;
    -webkit-text-decoration-line: underline;
    margin-right: 10px;
}

#event-title {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0.75rem 0 0;
    padding-bottom: 0.2rem !important;
    margin: 0;
}

#location-est {
    font-style: italic;
    font-size: 1em;
}

#location-home {
    font-size: 1em;
}

#location-icons {
    margin-right: 0.375rem;
    font-size: 1em;
}

/* Map Popup Events List */
#events-list {
    display: block;
    margin: 0;
    padding: 5px;
}

#event-start-date {
    display: inline-block;
    font-size: 1em;
    padding: 0;
    margin: 0;
}

#event-link {
    display: inline-block;
    font-size: 1em;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #0078A8;
}

/* Calendar */
#events-calendar {
    max-width: 1100px;
}

.react-autosuggest__container {
    width: 100%;
    position: relative;
    padding-bottom: 10px;
}

.react-autosuggest__input,
.react-autosuggest__suggestions-container {
    width: inherit;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    border: 1px solid #aaa;
    background-color: #ffffff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.fc-day-grid-event > .fc-content {
    white-space: normal!important;
    text-overflow: ellipsis!important;
    max-height: 30px!important;
}
.fc-day-grid-event > .fc-content:hover {
    max-height: none!important;
    z-index: 3!important;
}

.fc td {
    border: 1px solid transparent!important;
}

.fc-event-time,
.fc-event-title {
    padding: 0 1px !important;
}

.fc-title {
    white-space: normal;
}

body .fc {
    font-size: 0.8em!important;
}

.fc {
    text-align: right!important;
}

.fc-header-toolbar > div {
    width: 100%!important;
    text-align: center!important;
}

.fc-toolbar {
    align-items: start!important;
    margin: 0 0 5px !important;
}

.fc-listMonth-button {
    margin: 3px !important;
    height: 30px!important;
    width: 60px!important;
    border-radius: 0.25em!important;
}

.fc-dayGridMonth-button {
    margin: 3px !important;
    height: 30px!important;
    width: 60px!important;
    border-radius: 0.25em!important;
}

.fc-today-button {
    margin: 3px !important;
    height: 30px!important;
    width: 60px!important;
    border-radius: 0.25em!important;
}

.fc-unthemed td.fc-today {
    background: #add8e6!important;
}

.fc-listEvent-button {
    padding: 0 2px !important;
    width: 100px!important;
    color: #fff!important;
    background-color: #2c3e50!important;
    font-weight: 400!important;
    font-size: 1em!important;
    line-height: 1.5!important;
    border-color: #2c3e50!important;
}

.tooltip-inner {
    background-color: #00acd6;
    color: #fff;
    font-size: 0.7rem;
}

/* Event Popup */
@media screen and (max-width: 505px) {
    #react-aria-modal-dialog {
        max-width: 85%!important;
    }
}

.popup-event {
    background-color: #ffffff;
    border: 1px solid dimgray;
    color: #08233B;
    margin: 0 auto;
    min-width: 60%;
    max-width: 82%;
    border-radius: 4px;
    animation: showModal ease 0.5s;
}
@keyframes showModal {
    0% {
        opacity: 0;
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
    }
}

.modal-header {
    display: flow-root;
}

#react-aria-modal-dialog {
    text-align: center!important;
}

#event-header {
    color: #ffffff;
    background-color: #0074D9;
    font-size: 1.25em;
}

#header-location {
    font-size: 1.00em;
    border-bottom: none;
    padding: 0.5rem 0.5rem 0;
    text-decoration: underline;
}

#header-location-address {
    font-size: 1.00em;
    border-bottom: none;
    padding: 0;
}

#event-body {
    color: black;
    font-size: 1em;
    position: relative;
    padding: 0 3rem;
    text-align: left;
}

.event-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
}
.event-image:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

#event-dates-and-times {
    font-weight: bold;
    text-align: center;
}

#event-footer {
    font-size: 0.65em;
    color: black;
    border-top: none;
    float: center;
    position: relative;
}

#event-back {
    text-align: center;
    padding: 0.625rem;
}

/* Add Events */
.technical-details {
    padding: 0.625rem;
    border: 1px solid black;
    border-radius: 0.4em;
    margin: 0 auto;
}

/* Submit Event */
#top-icon {
    font-size: 1em;
    color: black;
    background-color: palegoldenrod;
    opacity: 0.7;
}

.go-to-top {
    border-radius: 5px;
    z-index: 3;
    position: fixed;
    top: 90vh;
    right: 15vw;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Submit Payment */
#paypal-button {
    width: 220px;
    margin: 20px auto;
}

.quantity-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin: 10px 0;
}

#quantity-button-span {
    padding-left: 5px;
    display: inline-block;
}

#increment {
    background-color: green;
    width: 40px;
}

#decrement {
    background-color: red;
    width: 40px;
}

#reset {
    background-color: black;
}

/* Contact Us */
.contact-us {
    background: #fff;
    border-radius: 0.4em!important;
    max-width: 450px!important;
    margin: 0 auto;
}

#contact-us-body {
    color: black;
}

#contact-link {
    font-size: 1.5rem;
}

.attribution-link {
    margin: 0;
}

#back-button {
    text-align: center;
    padding: 0.625rem;
}

#contact-us-footer {
    font-size: x-small;
    color: black;
}

.modal-footer {
    justify-content: center;
}

/* Press and Publicity */
.col-sm {
    margin-bottom: 30px;
}

img.press-thumbnail {
    border: 1px solid black;
}

.caption {
    font-weight: bold;
    font-size: 0.9em;
    text-align: center;
    font-style: italic;
}

.highlight {
    background-color: yellow;
    color: black;
}

.brightness {
    background-color: white;
    display: inline-block;

}
.brightness img:hover {
    opacity: 0.5;
}

.press-thumbnail {
    width: 300px;
    height: 389px;
}

.tilesWrap {
    padding: 0;
    list-style: none;
    text-align: center;
}

.tilesWrap li {
    display: inline-block;
    width: 20%;
    min-width: 200px;
    max-width: 230px;
    padding: 40px 20px;
    position: relative;
    vertical-align: top;
    margin: 10px;
    font-family: 'helvetica', san-serif;
    min-height: 25vh;
    background: #dfe3ee;
    border: 1px solid #252727;
}

.tilesWrap li h2 {
    font-size: 114px;
    margin: 0;
    position: absolute;
    opacity: 0.2;
    top: 50px;
    right: 10px;
}

.tilesWrap li h3 {
    font-size: 20px;
    color: #3b5998;
    margin-bottom: 5px;
}

.tilesWrap li p {
    font-size: 16px;
    line-height: 18px;
    color: black;
    margin-top: 5px;
    text-align: left;
}

.tilesWrap li button {
    background: transparent;
    border: 1px solid #3b5998;
    padding: 10px 20px;
    color: #3b5998;
    border-radius: 3px;
    cursor: pointer;
    overflow: hidden;
}
.tilesWrap li button:before {
    position: absolute;
    height: 100%;
    width: 120%;
    background: #b7b7b7;
    top: 0;
    opacity: 0;
    left: -140px;
    border-radius: 0 20px 20px 0;
    transition: all 0.3s ease-in-out;
}
.tilesWrap li button:hover {
    color: #262a2b;
}
.tilesWrap li button:hover:before {
    left: 0;
    opacity: 1;
}
.tilesWrap li:hover h2 {
    top: 0;
    opacity: 0.6;
}
.tilesWrap li:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: #fff;
    transform: skew(2deg, 2deg);
    background: #3b5998;
    background: -webkit-linear-gradient(to right, #3b5998, #3b5998);
    background: linear-gradient(to right, #3b5998, #3b5998);
}
.tilesWrap li:after {
    content: '';
    position: absolute;
    width: 40%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.02);
}

/* Not Found Page */
#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 710px;
    width: 100%;
    text-align: center;
    padding: 0 15px;
    line-height: 1.4;
}

.notfound .notfound-404 {
    height: 200px;
    line-height: 200px;
}

.notfound .notfound-404 h1 {
    font-size: 168px;
    margin: 0;
    color: #ff508e;
    text-transform: uppercase;
}

.notfound h2 {
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    color: #222;
    margin: 0;
}

.notfound a {
    display: inline-block;
    font-weight: 700;
    border-radius: 15px;
    text-decoration: none;
    color: #39b1cb;
}

.notfound a > .arrow {
    position: relative;
    top: -2px;
    border: solid #39b1cb;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}
@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        height: 122px;
        line-height: 122px;
    }
    .notfound .notfound-404 h1 {
        font-size: 122px;
    }
}